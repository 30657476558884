import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL, SELLER_PROFILE_BASE_URL } from '@src/globals';
import {
  GET_ALL_CONSIGNMENT_DATA, SEARCH_HUB,
  SEARCH_CUSTOMERS, FETCH_ONE, MARK_RTO, SET_ONHOLD, RELEASE_ONHOLD,
  RESCHEDULE_DELIVERY, UPDATE_USER_COLUMNS,
  REQUEST_DOWNLOAD, EDIT_CONSIGNMENT, GET_DELIVERY_LOCATION,
  SET_DELIVERY_LOCATION, MARK_UNDELIVERED_REASON, EDIT_NOTES_CRM_DASHBOARD,
   ADD_CONSIGNMENT, SEARCH_ACCOUNTS, GET_CONTENT_TYPE, LOST_CONSIGNMENT, REVOKE_CONSIGNMENT,
   SEARCH_HUB_WITH_REAL_FILTER,
   SEARCH_FILTERED_HUBS, GENERATE_REPORTS_RETAIL, BOOKING_STATUS_UPDATE, SOFTDATA_UPDATE,
   COURIER_BOOKING_UPDATE, COURIER_PARTNER_TRACKING_UPDATE, EWB_UPDATE, HANDLING_UNIT_PUSH_UPDATE,
   CONSIGNMENT_MANIFEST, DELIVERY_STATUS_UPDATE, TRIP_UPDATE,FDM_CTBS_UPDATE,
  MARK_CONSIGNMENT_AS_DELIVERED, RESCHEDULE_PICKUPS, MARK_CANCEL,
  REVOKE_CANCEL, GET_CANCELLATION_REASONS,
  UNASSIGN_ALL_CALLS_EMPLOYEE,
  GENERATE_REPORT_DASHBOARD, DOWNLOAD_SAMPLE_CSV_TO_UPDATE_CNS, DOWNLOAD_SAMPLE_EXCEL_TO_UPDATE_CNS,
  UPDATE_PAYMENT_CLEARED_FOR_CNS, MARK_CN_INSCAN_AT_HUB_BULK, MARK_CN_ARRIVAL_AT_HUB_BULK, REVERT_PICKUP_FAILED,
  REVERT_PICKUP_COMPLETED, REFRESH_EWAYBILL_DETAILS, REFRESH_ROUTE_NEXT_HUB,
  REFRESH_DESTINATION_AND_HOLDING_HUB, REFRESH_ORIGIN_HUB,
  UPDATE_ADDRESS_BULK, FETCH_CALL_LOGS, UPDATE_CALL_LOGS, USER_CONSENT,
  BULK_SET_TRANSPORTER_IDS,
  EFS_TO_PUSH_POD,
  CLIENT_TRACKING_STATUS_UPDATE,
  FETCH_RIDER_LIST_HUB, MARK_REVOKE_RTO,
  FETCH_RIDERS_OF_HUB_WITH_DESCENDANT,
  DOWNLOAD_SAMPLE_CSV_FOR_CNV, GET_IMAGE_URL, PRINT_DELIVERY_NOTE, SEARCH_ALL_WORKERS,
  REQUEST_REPORTS_DOWNLOAD, GET_APPROVAL_REQUESTS, CREATE_ADDRESS_REQUESTS, REJECT_REQUESTS,
  APPROVE_REQUESTS, GET_APPROVAL_METRICS, GET_HUB_DETAILS, FDM_HANDOVER_UPDATE,
  VERIFY_NATIONAL_DESTINATION_CODE, CALL_CENTER_METRICS, AWB_NO_STATUS, ASSIGN_OR_UNASSIGN_EMP,
  DOWNLOAD_ASSIGN_AWB_SAMPLE_CSV, GET_CALLS_OVERVIEW_DATA, SET_NOTES, FETCH_NOTES,
  UPDATE_PHONE_NUMBER,
  DOWNLOAD_SAMPLE_CSV_FOR_CONSIGNMENT, UPDATE_BULK_COD, UPDATE_RESCHEDULED_REASON,
  QWIK_SETTLEMENT_LOGS, RETRY_INTEGRATION, GET_PLANNING_DATA,
  ASSIGN_TO_PREVIOUS_TRIP, UPDATE_BULK_RESCHEDULE,
  RELEASE_EXCEPTION_ON_CONSIGNMENT, APPLY_EXCEPTION_ON_CONSIGNMENT,
  APPLY_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT,
  RELEASE_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT,
  FETCH_ADDRESS_NODE_DATA, UPDATE_DUTY_CHARGES_BULK,
  BULK_UPDATE_CONSIGNMENT_DETAILS, BULK_UPDATE_CONSIGNMENT_EVENT_NOTES,
  MARK_CONSIGNMENT_AS_PICKUP_COMPLETED, MARK_PICKUP_AWAITED,
  GET_PICKUP_SLOTS, RESCHEDULE_PICKUP, FETCH_ZONES, UPDATE_ZONE,
  FETCH_ZONE_TASK_RIDER_COUNT, UPDATE_CONSOLIDATED_PLANNING_COLUMNS,
  UPDATE_CONSOLIDATED_PLANNING_FILTERS, REQUEST_MODEL_CONSOLIDATED_PLANNING,
  MARK_CONSIGNMENT_AS_FREE_OF_COST, UPDATE_CONSIGNMENT_DESCRIPTION,
  ADD_COMMENT, MARK_CONSIGNMENT_AS_DELIVERED_V2, MARK_CONSIGNMENT_AS_CANCELLED,
  BULK_TRIGGER_INTEGRATION, SEARCH_SHELF_NUMBER, VALIDATE_INSCAN_CN, REMOVE_HU,
  UPDATE_EWB,
  MARK_SHIPMENT_DESTROYED, MARK_RTO_CONFIRMED, AUTHENTICATE_ACTIVATION_CODE,
  EWAY_EXTENSION, BULK_COURIER_PARTNER_REFERENCE_NUMBER_CHANGE_MANUAL,
  BRIM_BOOKING_UPDATE,
  FETCH_AND_VALIDATE_CONSIGNMENT,
  FETCH_CONSIGNMENT_PRICE_CALCULATOR_DATA, DOWNLOAD_CONSIGNMENT_PRICE_CALCULATOR_DATA,
  DOWNLOAD_SAMPLE_CSV_FOR_CHECK_CONSIGNMENT_PRICE, CHECK_CONSIGNMENT_PRICE,
  CUSTOMER_WEBHOOK_LOGS, RETRY_CUSTOMER_WEBHOOK_INTEGRATION,
  PIS_SAP_INTEGRATION_LOGS, BULK_UPDATE_POD,
  GET_MULTIPLE_S3_IMAGE_URL,
  GET_PLANNING_IDS, MARK_CN_INTRANSIT_TO_HUB_BULK, RETRY_BOOKING, CANCEL_BOOKING,
  FETCH_ADDRESS_HIERARCHY_DATA,
  DOWNLOAD_RIDER_EVENTS,
  MARK_PICKUP_COMPLETED, MARK_NOT_PICKEDUP,
  GET_BOOKING_MASTER_TABLE_DATA, GET_HUB_DATA_FROM_PINCODE,
  TRIGGER_OUT_BOUND,MARK_CN_ELIGIBLE_FOR_LM_PLANNING,
  FETCH_ADDRESS_NODE_CONFIG, FETCH_ADDRESS_NODES, FETCH_LOCALITY_FROM_ADDRESS_MAPPING,
  GET_SERVICE_TYPES, UPDATE_POD_SINGLE, GET_POD_SINGLE, SAVE_ADDRESS, EDIT_ADDRESS, FCM_TOKEN_UPDATE,
  STOP_DELIVERY_DETAILS, DMC_SCAN_DETAILS,DOWNLOAD_SAMPLE_CSV_FOR_UPDATE_CONSIGNMENT,
  FETCH_ORGANISATION_CONFIG, UPDATE_ORGANISATION_CONFIG,GET_NOTIFICATION_AUTH_TOKEN, UPDATE_USER_PREFERENCE,
  MARK_CONSIGNMENT_AS_OUT_FOR_DELIVERY,MARK_CONSIGNMENT_AS_OUT_FOR_PICKUP,
  GET_COURIER_HANDOVER_MANIFESTS,REVOKE_COURIER_HANDOVER, SELLER_SYNC_LOGS,
  GET_RIDER_FOR_HUB,GET_RIDER_HISTORY, GET_RIDER_HISTORY_AGGREGATE,
  SELLER_VENDOR_SYNC_RETRY,
  GET_GENERIC_ERP_LOGS,
  CANCELLATION_UPDATE,
  RETRY_INTEGRATION_LOGS,
  RETRY_DELIVERY_STATUS_INTEGRATION_LOGS,
  PICKUP_CREATION,
} from './routes';
import { GET, POST } from './apiHandler';
import { morphism } from 'morphism';
import { requestModelApprovalRequests, responseModelApprovalRequests, requestModelApprovalMetrics,
  requestModelCallsOverview, requestModelMyCalls, responseModelCN,
  requestModelConsolidatedPlanningColumns, requestModelConsolidatedPlanningFilter,
} from '../model/consignmentView';
import { requestModelGenericDownload } from '@model/genericFilterView';
import { requestSchema } from '@model/retailTripView';

const getMyCallsBody = (body) => {
  const requestObject = {
    ...body,
    ...body.appliedFilters,
  };
  delete requestObject.appliedFilters;
  return requestObject;
};

export const getAllConsignmentsData = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`, { ...body },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const addCommentsToPieces = async (
  body,
) => {
  return POST(`${API_BASE_URL}${ADD_COMMENT}`, { ...body });
};
export const searchHubData = async (queryString: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_HUB}?searchQuery=${queryString}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchHubDataWithPermission = async (queryString: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_HUB}?searchQuery=${queryString}&permissionFilter=1`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchHubWithrealFilter = (realFilter: boolean, defaultHubType?: string) =>
async (queryString: string, hubType?: string, hubCategoryId?: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_HUB_WITH_REAL_FILTER}`,
      {
        params: {
          hubType: hubType || defaultHubType,
          hubCategoryId,
          realFilter,
          searchQuery: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchHubWithGenericFilters = async (params:any = {}) => {
  const { hubType, defaultHubType, hubCategoryId, realFilter, activeFilter, queryString } = params;
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_HUB_WITH_REAL_FILTER}`,
      {
        params: {
          hubType: hubType || defaultHubType,
          hubCategoryId,
          realFilter,
          searchQuery: queryString,
          activeFilter,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
  }
};

export const searchAddressNodeData = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_ADDRESS_NODE_DATA}`, params);
};

export const getAddressHierarchyData = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_ADDRESS_HIERARCHY_DATA}`, params);
};

export const putNDRList = async (body) => {
  try {
    const response = await apiProvider.post(
   `${API_BASE_URL}${MARK_UNDELIVERED_REASON}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
     );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const submitNotes = async (body) => {
  try {
    const response = await apiProvider.post(
    `${API_BASE_URL}${EDIT_NOTES_CRM_DASHBOARD}`,
        body,
      {
        headers: await getAuthenticationHeaders(),
      },
      );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchCustomerCode = async (queryString: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_CUSTOMERS}?searchQuery=${queryString}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchShelfNumber = async (queryString: string) => {
    const response = await GET(`${API_BASE_URL}${SEARCH_SHELF_NUMBER}`, {searchQuery:queryString});
    return {
      data: response.data,
    };
};

export const fetchOne = async (refNo: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_ONE}=${refNo}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data[0],
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const markRTO = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${MARK_RTO}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const LostConsignment = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${LOST_CONSIGNMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const revokeConsignment = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${REVOKE_CONSIGNMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const setOnhold = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SET_ONHOLD}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const releaseOnhold = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RELEASE_ONHOLD}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const rescheduleDelivery = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RESCHEDULE_DELIVERY}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getDeliveryLocation = async (refNo: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_DELIVERY_LOCATION}?referenceNumber=${refNo}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data[0],
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const setDeliveryLocation = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SET_DELIVERY_LOCATION}`, { ...body },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const updateUserColumnList = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${UPDATE_USER_COLUMNS}`, { crm_dashboard_column_list: body },
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const requestDownload = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${REQUEST_DOWNLOAD}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const editConsignment = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${EDIT_CONSIGNMENT}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchAccounts = async(body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_ACCOUNTS}${body}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data : response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const updateRescheduledReason = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${UPDATE_RESCHEDULED_REASON}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data : response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getContentType = async() => {
  try {
    const response = await apiProvider.get(
      `${BUSINESS_BOOKING_API_URL}${GET_CONTENT_TYPE}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
      data : response.data.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const addConsignment = async(body) => {
  let apiObject:any = {
    customerReferenceNumber: body.customer_reference_number,
    referenceNumber: body.reference_number,
    courierType: body.load_type,
    consignmentType: body.consignment_type,
    srcAddress: {
      pincode: body.origin_details.pincode,
      name: body.origin_details.name,
      phone: body.origin_details.phone,
      alternatePhone: body.origin_details.alternatePhone,
      addressLine1: body.origin_details.address_line_1,
      addressLine2: body.origin_details.address_line_2,
      cityName: body.origin_details.city,
      stateName: body.origin_details.state,
      countryName: body.origin_details.country,
      latitude: body.origin_details.latitude,
      longitude: body.origin_details.longitude,
    },
    dstAddress: {
      pincode: body.destination_details.pincode,
      name: body.destination_details.name,
      phone: body.destination_details.phone,
      alternatePhone: body.destination_details.alternatePhone,
      addressLine1: body.destination_details.address_line_1,
      addressLine2: body.destination_details.address_line_2,
      cityName: body.destination_details.city,
      stateName: body.destination_details.state,
      countryName: body.destination_details.country,
      latitude: body.destination_details.latitude,
      longitude: body.destination_details.longitude,
    },
    weight: body.weight,
    serviceType: body.service_type_id,
    isRiskSurchargeApplicable: body.is_risk_surcharge_applicable,
    commodityId: body.commodity_id,
    codCollectionMode: body.cod_collection_mode,
    codFavorOf: body.cod_favor_of,
    codAmount: body.cod_amount,
    description: body.description,
    deliveryCharge: body.delivery_charge,
    customerId: body.customer_id,
    numberOfPieces: body.num_pieces,
    declaredPrice: body.declared_value,
    highValueDetails: {
      idNum: body.hv_id_num,
      name: body.hv_name,
      idExpiry: body.hv_id_expiry,
      issuePlace: body.hv_issue_place,
      nationality: body.hv_nationality,
      phone: body.hv_phone,
      amount: body.hv_amount,
      billedToMerchant: body.hv_billed_to_merchant,
    },
    piecesDetail: body.piecesDetail,
  };
  if (body.length || body.width || body.height) {
    apiObject = {
      ...apiObject,
      dimensions:{
        length:body.length,
        width:body.width,
        height:body.height,
      },
    };
  }
  try {
    const response = await apiProvider.post(
      `${BUSINESS_BOOKING_API_URL}${ADD_CONSIGNMENT}`,  apiObject,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const searchFilteredHubCode = async (queryString: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_FILTERED_HUBS}?q=${queryString}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const generateReportsRetail = async (params: any) => {
  try {
    const response = await apiProvider.post(
        `${API_BASE_URL}${GENERATE_REPORTS_RETAIL}`, { ...params } , {
          headers: await getAuthenticationHeaders(),
        });
    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess:
 false,
    };
  }
};

export const bookingStatusUpdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${BOOKING_STATUS_UPDATE}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const bookingBRIMLogs = async (referenceNumber) => {
	try {
	  const response = await apiProvider.get(
		`${API_BASE_URL}${BRIM_BOOKING_UPDATE}?reference_number=${referenceNumber}`,
		{
		  headers: await getAuthenticationHeaders(),
		},
	  );
	  return {
		data: response.data.data,
		isSuccess: true,
	  };
	} catch (err) {
	  const errorMessage = getErrorMessage(err);
	  return {
		errorMessage,
		isSuccess: false,
	  };
	}
  };

export const softDataUpdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SOFTDATA_UPDATE}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const ewbExtensionUpdate = async (referenceNumber) => {
  return GET(`${API_BASE_URL}${EWAY_EXTENSION}`, {reference_number:referenceNumber});
};

export const customerWebHookLogs = async (referenceNumber) => {
  return GET(`${API_BASE_URL}${CUSTOMER_WEBHOOK_LOGS}`, {reference_number:referenceNumber});
};

export const courierPartnerLogs = async (params) => {
   return GET(
      `${API_BASE_URL}${COURIER_BOOKING_UPDATE}`,
      params,
    );
};

export const courierPartnerTrackingLogs = async (params) => {
    return GET(
      `${API_BASE_URL}${COURIER_PARTNER_TRACKING_UPDATE}`,
      params,
    );
};

export const getLogsGeneric = async (params) => {
  return GET(
    `${API_BASE_URL}${GET_GENERIC_ERP_LOGS}`,
    params,
  );
};

export const EWBupdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${EWB_UPDATE}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const handlingUnitPushUpdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${HANDLING_UNIT_PUSH_UPDATE}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
//need to add EWB and Courier Booking here as well.
export const consignmentManifest = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${CONSIGNMENT_MANIFEST}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const pickupCreation = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${PICKUP_CREATION}?pickup_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getDmcScanDetails = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${DMC_SCAN_DETAILS}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getStopDeliveryDetails = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${STOP_DELIVERY_DETAILS}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const deliveryStatusUpdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${DELIVERY_STATUS_UPDATE}?reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const tripUpdate = async (referenceNumber) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TRIP_UPDATE}?trip_reference_number=${referenceNumber}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const markDelivered = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${MARK_CONSIGNMENT_AS_DELIVERED}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const reschedulePickup = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${RESCHEDULE_PICKUPS}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const markCancel = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${MARK_CANCEL}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    if(response.data && response.data.failures){
     return {
      data:{
        failures: response.data.failures,
      },
      isSuccess: true,
     };
    }
      return {
        data: response.data.data,
        isSuccess: true,
       };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const revokeCancel = (body) => {
  return POST(`${API_BASE_URL}${REVOKE_CANCEL}`, body);
};

export const getCancellationReasons = async () => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_CANCELLATION_REASONS}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const searchAllWorkers = async (queryStr: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${SEARCH_ALL_WORKERS}`,
      {
        params: {
          queryStr,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const generateReportsExpress = async (body) => {
  return POST(`${API_BASE_URL}${GENERATE_REPORT_DASHBOARD}`, body);
};
export const downloadSampleCsvCNUpdate = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_TO_UPDATE_CNS}`, params);
};

export const getPlanningId = async(params) => {
  return GET(`${API_BASE_URL}${GET_PLANNING_IDS}`, params);
};

export const downloadSampleExcelCNUpdate = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_EXCEL_TO_UPDATE_CNS}`, params, 'blob');
};
export const updatePaymentClearedForCNs = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_PAYMENT_CLEARED_FOR_CNS}`, body);
};
export const markConsignmentInscanAtHubBulk = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CN_INSCAN_AT_HUB_BULK}`, body);
};

export const markConsignmentArrivalAtHubBulk = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CN_ARRIVAL_AT_HUB_BULK}`, body);
};

export const revertPickupFailed = async (body) => {
  return POST(`${API_BASE_URL}${REVERT_PICKUP_FAILED}`, body);
};
export const revertPickupCompleted = async (body) => {
  return POST(`${API_BASE_URL}${REVERT_PICKUP_COMPLETED}`, body);
};
export const refreshEwaybillDetails = async (body) => {
  return POST(`${API_BASE_URL}${REFRESH_EWAYBILL_DETAILS}`, body);
};
export const refreshOriginHub = async (body) => {
  return POST(`${API_BASE_URL}${REFRESH_ORIGIN_HUB}`, body);
};
export const refreshRouteNextHub = async (body) => {
  return POST(`${API_BASE_URL}${REFRESH_ROUTE_NEXT_HUB}`, body);
};
export const refreshHoldingAndDestinationHub = async (body) => {
  return POST(`${API_BASE_URL}${REFRESH_DESTINATION_AND_HOLDING_HUB}`, body);
};

export const updateAddressBulk = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_ADDRESS_BULK}`, body);
};

export const markCnEligibleForLmPlanning = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CN_ELIGIBLE_FOR_LM_PLANNING}`, body);
};

export const updateDutyChargesBulk = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_DUTY_CHARGES_BULK}`, body);
};

export const updateBulkCOD = async (params) => {
  return POST(`${API_BASE_URL}${UPDATE_BULK_COD}`, params);
};
export const updateBulkReschedule = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_BULK_RESCHEDULE}`, body);
};

export const fetchCallLogs = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_CALL_LOGS}`, params);
};

export const updateCallLogs = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_CALL_LOGS}`, body);
};

export const userConsent = async (body) => {
  return POST(`${API_BASE_URL}${USER_CONSENT}`, body);
};
export const setTransporterId = async (body) => {
  return POST(`${API_BASE_URL}${BULK_SET_TRANSPORTER_IDS}`, body);
};
export const clientTrackingStatusUpdate = async (refNumber) => {
  return GET(`${API_BASE_URL}${CLIENT_TRACKING_STATUS_UPDATE}?reference_number=${refNumber}`);
};
export const fdmHandoverUpdate = async (refNumber) => {
  return GET(`${API_BASE_URL}${FDM_HANDOVER_UPDATE}`, {reference_number : refNumber});
};
export const fetchRiderList = async (hub_id , queryString) => {
  return GET(`${API_BASE_URL}${FETCH_RIDER_LIST_HUB}?hubId=${hub_id}&queryStr=${queryString}`);
};
export const fetchRidersGivenHubs = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_RIDERS_OF_HUB_WITH_DESCENDANT}`, body);
};
export const downloadSampleCsvForCNV = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_FOR_CNV}`, params);
};

export const getImageURL = async(body) => {
  return POST(`${API_BASE_URL}${GET_IMAGE_URL}`, body);
};

export const printDeliveryNote = async(body) => {
  return POST(`${API_BASE_URL}${PRINT_DELIVERY_NOTE}`, body, undefined, true);
};

export const revokeRTO = async(body) => {
  return POST(`${API_BASE_URL}${MARK_REVOKE_RTO}`, body);
};

export const markUndelivered = async(body) => {
  return POST(`${API_BASE_URL}${MARK_UNDELIVERED_REASON}`, body);
};

export const markShipmentDestroyed = async(body) => {
  return POST(`${API_BASE_URL}${MARK_SHIPMENT_DESTROYED}`, body);
};

export const markPickupCompleted = async(body) => {
  return POST(`${API_BASE_URL}${MARK_PICKUP_COMPLETED}`, body);
};

export const markNotPickedup = async(body) => {
  return POST(`${API_BASE_URL}${MARK_NOT_PICKEDUP}`, body);
};

export const markRtoConfirmed = async(body) => {
  return POST(`${API_BASE_URL}${MARK_RTO_CONFIRMED}`, body);
};

export const requestReportsDownload = async(body) => {
  return POST(`${API_BASE_URL}${REQUEST_REPORTS_DOWNLOAD}`, body);
};

export const getApprovalRequests = async(body) => {
  const response = await POST(`${API_BASE_URL}${GET_APPROVAL_REQUESTS}`,
    morphism(requestModelApprovalRequests, body));
  return {
    ...response,
    data: morphism(responseModelApprovalRequests, response.data?.result),
  };
};

export const getApprovalMetrics = async(body) => {
  const response = await POST(`${API_BASE_URL}${GET_APPROVAL_METRICS}`,
    morphism(requestModelApprovalMetrics, body));
  return {
    ...response,
    data: response.data?.bucket_metrics,
  };
};

export const createAddressRequest = async(body) => {
  return POST(`${API_BASE_URL}${CREATE_ADDRESS_REQUESTS}`, body);
};

export const rejectRequests = async(body) => {
  return POST(`${API_BASE_URL}${REJECT_REQUESTS}`, body);
};

export const approveRequests = async(body) => {
  return POST(`${API_BASE_URL}${APPROVE_REQUESTS}`, body);
};

export const getHubDetails = async (hubId) => {
  return GET(`${API_BASE_URL}${GET_HUB_DETAILS}/${hubId}/details`);
};

export const verifyNationalDestinationHubCode = async (body) => {
  return POST(`${API_BASE_URL}${VERIFY_NATIONAL_DESTINATION_CODE}`,body);
};

export const downloadSampleCsvForConsignment = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_FOR_CONSIGNMENT}`, params, 'blob');
};

export const getSummaryMetrics = async(body) => {
  const response = await POST(`${API_BASE_URL}${CALL_CENTER_METRICS}`, body);
  return {
    ...response,
    data: response.data?.metric_data,
  };
};

export const awbNoStatus = async(body) => {
  return POST(`${API_BASE_URL}${AWB_NO_STATUS}`, body);
};

export const assignOrUnassignEmp = async(body) => {
  return POST(`${API_BASE_URL}${ASSIGN_OR_UNASSIGN_EMP}`, body);
};

export const unassignAllCallsEmployee = async(body) => {
  return POST(`${API_BASE_URL}${UNASSIGN_ALL_CALLS_EMPLOYEE}`, body);
};

export const downloadAssignAwbSampleCSV = async() => {
  return GET(`${API_BASE_URL}${DOWNLOAD_ASSIGN_AWB_SAMPLE_CSV}`);
};

export const getCallsOverviewData = async(body) => {
  const response = await POST(`${API_BASE_URL}${GET_CALLS_OVERVIEW_DATA}`,
    morphism(requestModelCallsOverview, body));
  return response.isSuccess ? {
    ...response,
    data: morphism(responseModelApprovalRequests, response.data?.result),
  } : response;
};

export const getMyCallsData = async(body) => {
  const transformedMyCallsBody = getMyCallsBody(morphism(requestModelMyCalls, body));

  const authHeaders = await getAuthenticationHeaders();
  const employeeId = authHeaders['user-id'] || '';
  transformedMyCallsBody.employeeId = employeeId;

  const response = await POST(`${API_BASE_URL}${GET_ALL_CONSIGNMENT_DATA}`,transformedMyCallsBody);
  return {
    ...response,
    data: morphism(responseModelCN, response.data),
  };
};
export const fdmCtbsUpdate = async (manifestNumber) => {
  return GET(`${API_BASE_URL}${FDM_CTBS_UPDATE}?manifest_number=${manifestNumber}`);
};

export const cancellationUpdate = async (referenceNumber) => {
  return GET(`${API_BASE_URL}${CANCELLATION_UPDATE}?reference_number=${referenceNumber}`);
};

export const setNotes = async(body) => {
  return POST(`${API_BASE_URL}${SET_NOTES}`, body);
};

export const fetchNotes = async(body) => {
  return POST(`${API_BASE_URL}${FETCH_NOTES}`, body);
};

export const updatePhoneNumber = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_PHONE_NUMBER}`, body);
};

export const qwikSettlementLogs = async (refNumber) => {
  return GET(`${API_BASE_URL}${QWIK_SETTLEMENT_LOGS}?reference_number=${refNumber}`);
};

export const pisSapIntegrationLogs = async (refNumber) => {
  return GET(`${API_BASE_URL}${PIS_SAP_INTEGRATION_LOGS}?pis_reference_number=${refNumber}`);
};

export const retryIntegration = async (body) => {
  return POST(`${API_BASE_URL}${RETRY_INTEGRATION}`, body);
};

export const retryIntegrationLogs = async (body) => {
  return POST(`${API_BASE_URL}${RETRY_INTEGRATION_LOGS}`, body);
};

export const retryDeliveryStatusIntegrationLogs = async (body) => {
  return POST(`${API_BASE_URL}${RETRY_DELIVERY_STATUS_INTEGRATION_LOGS}`, body);
};

export const retryCustomerWebHookIntegration = async (body) => {
  return POST(`${API_BASE_URL}${RETRY_CUSTOMER_WEBHOOK_INTEGRATION}`, body);
};

export const getConsolidatedPlanningData = async(body) => {
  return POST(`${API_BASE_URL}${GET_PLANNING_DATA}`, body);
};
export const assignToPreviousTrip = async (body) => {
  return POST(`${API_BASE_URL}${ASSIGN_TO_PREVIOUS_TRIP}`, body);
};

export const applyExceptionOnConsignments = async (body) => {
  return POST(`${API_BASE_URL}${APPLY_EXCEPTION_ON_CONSIGNMENT}`, body);
};

export const releaseExceptionOnConsignments = async (body) => {
  return POST(`${API_BASE_URL}${RELEASE_EXCEPTION_ON_CONSIGNMENT}`, body);
};

export const applyPieceLevelExceptionOnConsignments = async (body) => {
  return POST(`${API_BASE_URL}${APPLY_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT}`, body);
};

export const releasePieceLevelExceptionOnConsignments = async (body) => {
  return POST(`${API_BASE_URL}${RELEASE_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT}`, body);
};

export const updateConsignorConsigneeCode = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_CONSIGNMENT_DETAILS}`, body);
};

export const updateConsignmentEventNotes = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_CONSIGNMENT_EVENT_NOTES}`, body);
};

export const markConsignmentAsPickupCompleted = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_PICKUP_COMPLETED}`, body);
};

export const markPickupAwaitedAction = async (body) => {
  return POST(`${API_BASE_URL}${MARK_PICKUP_AWAITED}`, body);
};

export const getPickupSlots = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${GET_PICKUP_SLOTS}`, params);
};

export const reschedulePickupFuture = async (body) => {
  return POST(`${API_BASE_URL}${RESCHEDULE_PICKUP}`, body);
};

export const markConsignmentCancel = async (
  body,
) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_CANCELLED}`, body);
};

export const markDeliveredV2 = async (
  body,
) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_DELIVERED_V2}`, body);
};

export const fetchZoneWithSearch = async (params) => {
  const response = await GET(`${API_BASE_URL}${FETCH_ZONES}`, params);
  return response.data;
};

export const updateZone = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_ZONE}`, body);
};

export const fetchZoneTaskRiderCount = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_ZONE_TASK_RIDER_COUNT}`, body);
};

export const updateConsolidatedPlanningColumnList = async (body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_CONSOLIDATED_PLANNING_COLUMNS}`,
    morphism(requestModelConsolidatedPlanningColumns, body),
  );
};

export const updateConsolidatedPlanningFilters = async (body) => {
  return POST(
    `${API_BASE_URL}${UPDATE_CONSOLIDATED_PLANNING_FILTERS}`,
      morphism(requestModelConsolidatedPlanningFilter, body),
    );
};

export const requestDownloadConsolidatedPlanning = async (body) => {
  return POST(
    `${API_BASE_URL}${REQUEST_MODEL_CONSOLIDATED_PLANNING}`,
      morphism(requestModelGenericDownload, body),
    );
};

export const markConsignmentAsFreeOfCost = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_FREE_OF_COST}`, body);
};

export const markConsignmentAsOutForDelivery = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_OUT_FOR_DELIVERY}`, body);
};

export const markConsignmentAsOutForPickup = async (body) => {
  return POST(`${API_BASE_URL}${MARK_CONSIGNMENT_AS_OUT_FOR_PICKUP}`, body);
};

export const updateConsignmentDescription = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_CONSIGNMENT_DESCRIPTION}`, body);
};

export const validateInScannedConsignments = async (body) => {
  return POST(`${API_BASE_URL}${VALIDATE_INSCAN_CN}`, body);
};

export const bulkTriggerIntegration = async (body) => {
  return POST(`${API_BASE_URL}${BULK_TRIGGER_INTEGRATION}`, body);
};

export const removeHuFromParent = async (body) => {
  return POST(`${API_BASE_URL}${REMOVE_HU}`, body);
};

export const updateEWBAction = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_EWB}`, body);
};

export const bulkUpdatePOD = async (body) => {
  return POST(`${API_BASE_URL}${BULK_UPDATE_POD}`, body);
};

export const triggerEfsToPushPod = async (body) => {
  return POST(`${API_BASE_URL}${EFS_TO_PUSH_POD}`, body);
};

export const generateMultipleS3ImageUrl = async (body) => {
  return POST(`${API_BASE_URL}${GET_MULTIPLE_S3_IMAGE_URL}`, body);
};

export const markConsignmentLost = async (body) => {
  return POST(`${API_BASE_URL}${LOST_CONSIGNMENT}`,body);
};

export const verifyActivationCode = async (body) => {
  return POST(`${API_BASE_URL}${AUTHENTICATE_ACTIVATION_CODE}`, body);
};

export const fetchConsignmentPriceCalculatorData = async (body) => {
  return POST(`${API_BASE_URL}${FETCH_CONSIGNMENT_PRICE_CALCULATOR_DATA}`, body);
};

export const downloadConsignmentPriceCalculatorData = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_CONSIGNMENT_PRICE_CALCULATOR_DATA}`, body);
};

export const downloadSampleCsvForCheckConsignmentPrice = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_FOR_CHECK_CONSIGNMENT_PRICE}`, params);
};

export const checkConsignmentPriceCalculatorData = async (body) => {
  return POST(`${API_BASE_URL}${CHECK_CONSIGNMENT_PRICE}`, body);
};

export const bulkCourierPartnerReferenceNumberChangeManual = async (body) => {
  return POST(`${API_BASE_URL}${BULK_COURIER_PARTNER_REFERENCE_NUMBER_CHANGE_MANUAL}`, body);
};

export const fetchAndValidateConsignment = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_AND_VALIDATE_CONSIGNMENT}`, params);
};

export const markConsignmentInTransitToHubBulk = async (params) => {
  return POST(`${API_BASE_URL}${MARK_CN_INTRANSIT_TO_HUB_BULK}`, params);
};

export const retryBooking = async (params) => {
  return POST(`${API_BASE_URL}${RETRY_BOOKING}`, params);
};

export const cancelBooking = async (params) => {
  return POST(`${API_BASE_URL}${CANCEL_BOOKING}`, params);
};

export const downloadRiderEvents = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_RIDER_EVENTS}`, body, await getAuthenticationHeaders() , true);
};

export const getDstServiceability = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${GET_BOOKING_MASTER_TABLE_DATA}`,params);
};

export const getHubNameFromPincode = async (params) => {
  return GET(`${API_BASE_URL}${GET_HUB_DATA_FROM_PINCODE}`,params);
};

export const triggerOutBoundEvent = async (params) => {
  return POST(`${API_BASE_URL}${TRIGGER_OUT_BOUND}`, params);
};

export const getServiceTypes = async () => {
  return GET(`${BUSINESS_BOOKING_API_URL}${GET_SERVICE_TYPES}`);
};

export const fetchAddressNodeConfig = async() => {
  return GET(`${API_BASE_URL}${FETCH_ADDRESS_NODE_CONFIG}`);
};

export const fetchAddressNodesDetail = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_ADDRESS_NODES}`, params);
};

export const fetchLocalityAddressFromNode = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_LOCALITY_FROM_ADDRESS_MAPPING}`, params);
};

export const updatePODListForSingleCN = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_POD_SINGLE}`, body);
};

export const getPODListForSingleCN = async (params) => {
  return GET(`${API_BASE_URL}${GET_POD_SINGLE}`, params);
};

export const saveAddress = async (params) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${SAVE_ADDRESS}`, params);
};

export const editAddress = async (params) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${EDIT_ADDRESS}`, params);
};

export const updateFcmToken = async (params) => {
  return POST(`${API_BASE_URL}${FCM_TOKEN_UPDATE}`, params);
};

export const fetchOrganisationConfig = async (params) => {
  return POST(`${API_BASE_URL}${FETCH_ORGANISATION_CONFIG}`, params);
};

export const updateOrganisationConfig = async (params) => {
  return POST(`${API_BASE_URL}${UPDATE_ORGANISATION_CONFIG}`, params);
};
export const downloadSampleCsvForUpdateConsignment = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_FOR_UPDATE_CONSIGNMENT}`, params, 'blob');
};

export const getNotificationAuthToken = async () => {
  return GET(`${API_BASE_URL}${GET_NOTIFICATION_AUTH_TOKEN}`);
};

export const updateUserNotificationPreference = async (params) => {
  return POST(`${API_BASE_URL}${UPDATE_USER_PREFERENCE}`, params);
};

export const getCourierHandoverManifests = async (params) => {
  return GET(`${API_BASE_URL}${GET_COURIER_HANDOVER_MANIFESTS}`, params);
};

export const revokeCourierHandover = async (params) => {
  return POST(`${API_BASE_URL}${REVOKE_COURIER_HANDOVER}`, params);
};

export const sellerSyncLogs = async (params) => {
  return POST(`${SELLER_PROFILE_BASE_URL}${SELLER_SYNC_LOGS}`, {
    seller_id: params.reference_number,
    log_type: params.log_type,
  });
};

export const retryVendorSync = async (body) => {
  return POST(`${SELLER_PROFILE_BASE_URL}${SELLER_VENDOR_SYNC_RETRY}`, body);
};

export const getRiderForHub = async (params) => {
  return GET(`${API_BASE_URL}${GET_RIDER_FOR_HUB}`, params);
};

export const getRiderHistory = async (params,riderId) => {
  return GET(`${API_BASE_URL}${GET_RIDER_HISTORY}/${riderId}`, params);
};

export const getRiderAggregateHistory = async (params) => {
  return GET(`${API_BASE_URL}${GET_RIDER_HISTORY_AGGREGATE}`, params);
};
